export default [
    { headerName: 'ID', field: 'id' },
    { headerName: 'LEA', field: 'lea', initialHide: true, filter: false },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    { headerName: 'Update Date', field: 'updateDate' },
    { headerName: 'Is Deleted', field: 'isDeleted', filter: false },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Student ID',
        field: 'studentId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    { headerName: 'Grade', field: 'grade', filter: false },
    { headerName: 'Start Date', field: 'startDate', filter: false },
    { headerName: 'End Date', field: 'endDate', filter: false },
    { headerName: 'Exit Code', field: 'exitCode', filter: false },
    { headerName: 'Entry Code', field: 'entryCode', filter: false },
    {
        headerName: 'Primary ADA Indicator',
        field: 'primaryADAIndicator',
        filter: false,
    },
    { headerName: 'Session Type', field: 'sessionType', filter: false },
]
